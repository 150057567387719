.Scrollable {
  overflow: visible !important;
  padding-right: 0px !important;
}

.Dialog {
  max-width: 414px;
  position: fixed;
  z-index: 1050;
}

.Drawer {
  left: initial;
  height: initial;
  top: initial;
  width: initial;
}

.Content {
  padding: 0 20px;
}

.Image {
  display: block;
  margin: 28px auto 0;
  height: 144px;
}

.Text {
  margin: 28px 0 40px;
  text-align: center;
}

.Button {
  margin: 0 0 30px;
}

.Canvas {
  z-index: 1049 !important;
}

@media (min-width: 768px) {
  .Text {
    font-size: 30px;
  }
}
