.ControlsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 30px 10px;
  background: var(--empty-background-color);
}

.ControlsButtons {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.ControlsButtons button {
  width: 150px;
  margin-bottom: 24px;
  max-width: 100%;
}

.VideoTimeSliders {
  margin-bottom: 16px;
}

@media (min-width: 575px) {
  .ControlsButtons button {
    margin-bottom: 0px;
    width: 40%;
  }
  .VideoTimeSliders {
    margin-bottom: 16px;
  }
}

.AddButton {
  width: 340px !important;
  min-height: 40px !important;
  padding-bottom: 0.6rem;
  padding-top: 0.6rem;
}
