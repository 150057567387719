.ExternalLink {
  margin: 15px;
  background-color: rgba(0, 0, 0, 0.29);
  border-radius: 999px;
  padding: 10px;
  padding: 8px 15px;
  text-decoration: none;
}

.ExternalLink > * {
  display: inline-block;
}

.ExternalLink > i {
  color: white;
  transform-origin: bottom;
  transform: rotate(45deg);
  margin-right: 10px;
}

.ExternalLink > p {
  color: #fff;
}
