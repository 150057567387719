.Root {
  background: #eee;
  height: 8px;
  width: 100%;
}

.Bar {
  border-radius: 4px;
  background: black;
  height: 8px;
  margin: 0 auto;
  width: 0%;
}
