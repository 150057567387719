.BackgroundOne {
  position: relative;
  top: 0;
  z-index: 0;
}

.BackgroundTwo {
  position: relative;
  top: 0;
  z-index: 0;
}
