.Root {
  overflow: hidden;
  position: relative;
}

.ArrowButtonContainer {
  display: flex;
  justify-content: center;
}

.ArrowButton {
  box-shadow: none;
  color: initial;
  height: 30px;
  font-size: 22px;
  font-weight: bold;
  position: absolute;
  width: 30px;
  z-index: 1;

  &.active,
  &.disabled,
  &.focus,
  &:focus,
  &:hover {
    color: initial;
  }
}

.Mask {
  mask: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  mask-repeat: repeat-x;
  mask-size: auto 40px;
}
