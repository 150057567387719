.Root {
  min-height: 210px;
  padding: 32px;
}

.Controls {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ThanksButton {
  width: 100%;
  margin-right: 32px;
  margin-left: 32px;
}

.Title {
  margin-bottom: 12px;
  text-align: center;
}

.Footer {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
}
.FooterTitle {
  word-break: break-word;
  text-align: center;
  color: #959a9e;
}

.FooterButton {
  margin-top: -10px;
  margin-bottom: 8px;
  padding: 0px;
}

.FooterButton > * {
  text-transform: initial;
}

.CloseButton {
  font-size: 16px;
  height: 28px;
  right: 24px;
  position: absolute;
  top: 20px;
  width: 28px;
}
