.Header {
  background-color: var(
    --header-background-color,
    var(--empty-background-color)
  );
  border-bottom: 1px solid #ddd;
  display: flex;
  height: 40px;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
}

.Logo {
  background: center/contain no-repeat var(--logo-image);
  height: 50%;
}
