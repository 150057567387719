.StartEndControls {
  display: flex;
  justify-content: space-between;
}

.StartEndInput {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.StartEndInput input {
  width: 30px;
  padding: 5px;
  background: transparent;
  border: none;
  text-align: right;
}

.StartEndInput i {
  position: absolute;
  right: 8px;
  top: 28px;
  color: #0066ff;
}

.TextInputHeader {
  margin-bottom: 8px;
}

.TimeInput {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  background: white;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.InputReset::before {
  font-size: 23px;
  position: relative;
  top: 6px;
}
