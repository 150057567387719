.Root {
  display: flex;
  justify-content: center;
}

.ReplayButton {
  font-size: 16px;
}

.ReplayIcon {
  font-size: 26px;
  margin-right: 8px;
}

@media (min-width: 768px) {
  .ReplayButton {
    font-size: 18px;
  }
}
