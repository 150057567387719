.Root {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
}

.Placeholder {
  border-radius: 4px;
  flex: 1 1 140px;
  height: 54px;
  margin: 4px;
}

@media (min-width: 360px) {
  .Placeholder {
    flex: 1 1 150px;
  }
}
