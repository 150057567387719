.Root {
  background: #fff;
  border-radius: 14px;
  box-shadow: 0px 8px 32px #00000026;
  padding: 28px 25px 21px 25px;
  max-width: 344px;
  width: 100%;

  & > *:last-child {
    margin-bottom: 0;
  }
}

.Logo {
  display: block;
  height: 46px;
  margin: 0 auto 24px;
  object-fit: contain;
}

.Title {
  display: flex;
  font-weight: bold;
  font-size: 20px;
  justify-content: center;
  text-align: center;
  margin-bottom: 16px;
}

.PaymentButton {
  position: relative;
  z-index: 2;
  height: 48px;
  margin-bottom: 16px;
}

.Description {
  display: flex;
  font-size: 16px;
  justify-content: center;
  text-align: center;
  margin: 20px 0 24px;
  opacity: 0.8;
}

.FinePrint {
  display: flex;
  font-size: 10px;
  justify-content: center;
  opacity: 0.8;
  white-space: pre-line;
}
