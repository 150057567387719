.Root {
  min-width: 320px;
  position: absolute;
  z-index: 1;
}

.Item {
  padding-bottom: 30px;
}

.Invisible * {
  visibility: hidden !important;
}

.ChallengeCard {
  height: 234px;
  max-width: 342px;
  margin: auto;
}

.ChallengeCard > div {
  min-height: 234px;
  padding: 16px 40px;
  width: 342px;
}
