$white: #ffffff;

.Button {
  background-color: $white;
  font-size: 27px;

  &.active,
  &:active,
  &:not(:disabled):not(.disabled):active {
    background-color: darken($white, 10%);
    border-color: darken($white, 12.5%);
  }

  &.disabled,
  &:disabled {
    background-color: $white;
    border-color: $white;
  }

  &.focus,
  &:focus,
  &:hover {
    background-color: darken($white, 7.5%);
    border-color: darken($white, 7.5%);
  }
}

.Icon {
  font-size: 27px;
}
