.HeightBased {
  z-index: 1;
  width: auto;
  height: 100%;
  position: block;
  display: inline-block;
}

.WidthBased {
  z-index: 1;
  width: 100%;
  height: auto;
  position: block;
  display: inline-block;
}

.Child {
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
