.VideoPlayerContainer {
  height: auto;
  width: auto;
  position: relative;
}

.VideoPlayerContainer iframe,
.VideoPlayerContainer video {
  display: block;
}

.MediaTypeVideo {
  position: relative;
  box-sizing: border-box;
  border: var(--gift-item-border);
  border-radius: var(--gift-item-border-radius);
  box-shadow: var(--gift-item-box-shadow);
}

.MediaTypeVideo > div {
  position: absolute;
}

.MuteButton {
  z-index: 2;
  position: absolute;
  left: 4px;
  bottom: 4px;
  width: 48px;
  height: 48px;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.Mp4MuteButton {
  bottom: 6px !important;
}

.MuteButton i {
  color: white;
  font-size: 30px;
}

.MuteButton:hover {
  cursor: pointer;
}

.PlaybackButton {
  font-size: 25px;
  position: absolute;
  z-index: 1;
  transition: all 0.3s;
  box-sizing: border-box;
}
