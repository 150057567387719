.Spinner {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 11px;
  margin: 0 auto;
}
.Spinner div {
  position: absolute;
  top: 0px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #333;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.Spinner div:nth-child(1) {
  left: 6px;
  animation: Spinner1 0.6s infinite;
}
.Spinner div:nth-child(2) {
  left: 6px;
  animation: Spinner2 0.6s infinite;
}
.Spinner div:nth-child(3) {
  left: 26px;
  animation: Spinner2 0.6s infinite;
}
.Spinner div:nth-child(4) {
  left: 45px;
  animation: Spinner3 0.6s infinite;
}
@keyframes Spinner1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes Spinner3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes Spinner2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
