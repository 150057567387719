.Snap {
  height: 100%;
  scroll-snap-type: y mandatory;
  overflow: scroll;
}

.ItemSnap {
  scroll-snap-align: center;
}

.InnerItem {
  transition: transform 0.1s linear;
}

.Header {
  transition: height 0.2s ease;
}
