.ToLabel {
  margin-bottom: 24px;
  text-align: center;
}

.Title {
  margin: 0 auto 16px;
  text-align: center;
}

.Subtitle {
  color: #767676;
  margin-bottom: 24px;
  text-align: center;
}

.CountdownTimer {
  margin-bottom: 24px;
}

.AddToCalendar {
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
