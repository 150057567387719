.Root {
  align-items: center;
  display: flex;
  height: 32px;
}

.CopyButton {
  padding: 6px;
}

.Center {
  justify-content: center;
  position: relative;
  width: 100%;

  & .CopyButton {
    position: absolute;
    right: 0;
  }
}
