.AddToCalendar {
  width: 200px;
  max-width: 75%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.AddToCalendarButton {
  width: 208px;
  height: 40px;
  border-radius: 5px;
}

.Popover {
  background: #333;
  min-width: 150px;
}

.Popover :global(.arrow)::after {
  border-top-color: #333;
}

.Links {
  text-decoration: none;
}

.Links * {
  display: block;
  color: white;
  text-align: center;
  padding: 10px;
}

.Links *:hover {
  color: var(--primary-accent-color);
}
