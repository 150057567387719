// See: https://github.com/twbs/bootstrap/tree/main/scss

$primary: if($app-name == 'discover', #333333, #5b0ac0);
$secondary: if($app-name == 'discover', #222222, #5b0ac0);

$btn-border-radius: 3px;
$btn-font-size: 14px;
$btn-font-weight: normal;
$btn-text-transform: uppercase;

$font-family-sans-serif: 'AvenirNextLTPro', sans-serif;
