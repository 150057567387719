// See: https://getbootstrap.com/docs/4.4/getting-started/theming/
// See: https://react-bootstrap.github.io/getting-started/theming/
// See: https://create-react-app.dev/docs/adding-bootstrap/

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$app-name: 'reveal';

@import './theme/common.scss';

@import './theme/active/theme.scss';

@import '~bootstrap/scss/bootstrap.scss';

@import './theme/root.scss';
@import './theme/active/root.scss';
