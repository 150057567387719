.Modal {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(40, 60, 70, 0.8);
  z-index: 99999;
}

.CloseButton {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.CenterContent {
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100% !important;
}

.BottomContent {
  height: 100%;
}

.FullScreen {
  height: 100%;
  width: 100%;
  position: fixed;
}

.Rounded {
  border-radius: 12px;
}
