.Root {
  position: relative;
  /* overflow: hidden; */
  border-radius: 15px;
}

.Root div {
  position: relative;
}

.Body {
  width: 100%;
  height: 100%;
}

.Body > * {
  left: 0;
}

:global(.open) .Body {
  height: 66.66%;
  top: 33.33%;
  position: absolute !important;
}

.EnvelopeBack,
.EnvelopePattern {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  background-color: #d8e2e8;
  overflow: auto;
  position: absolute !important;
}

.EnvelopeBack {
  z-index: 0;
}

/* In combination with the shape's background z-index of 0, the foreground's
 * z-index of 100 allows content to have a range of z-indexes ensuring the
 * content exits the shape between the shape's background and its foreground
 */
.EnvelopePattern {
  z-index: 100;
}

.EnvelopePatternOpen {
  clip-path: polygon(0% 0%, 50% 50%, 100% 0%, 100% 100%, 0 100%);
}

:global(.closed) .Body {
  height: 100%;
}

.SvgPatternImage {
  user-select: none;
}

.BottomShadowImage {
  pointer-events: none;
  user-select: none;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 15px;

  /* The element needs to be visible above the envelope background/pattern */
  z-index: 101;
}

.TopOpenImage {
  pointer-events: none;
  user-select: none;
  width: 99.4%;
  height: 33.33%;
  position: relative;
  border-radius: 14px;
  top: 7px;
  left: 0.3%;
  right: 0.3%;
  z-index: 0;
}

.TopClosedShadowImage {
  pointer-events: none;
  user-select: none;
  position: relative;
  width: 100%;

  /* The element needs to be visible above the envelope background/pattern */
  z-index: 101;
}
