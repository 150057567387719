.CountdownTimer {
  --countdown-digit-border-radius: 20%;
  display: flex;
  font-size: 11px;
  justify-content: center;
}

.Unit {
  font-size: 3em;
}

.UnitSeparator {
  color: #009dff;
  display: inline-block;
  font-size: 3em;
  height: 1em;
  margin: 0.5em 0;
  vertical-align: top;
  width: 1em;
}

.UnitText {
  color: var(--text-color, #979899);
  font-size: 0.25em;
  text-align: center;
}

.Digit {
  display: inline-block;
  font-family: 'Inter';
  height: 1.25em;
  margin: 3px;
  position: relative;
  width: 0.75em;
}

.Days.Unit {
  display: block;
}

.Days.UnitSeparator {
  display: none;
}

.Unit:not(.Days) {
  font-size: 3em;
}

.UnitSeparator {
  font-size: 2em;
}

.Days .Num {
  color: #009dff;
}

.DigitUnit {
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  border-radius: var(--countdown-digit-border-radius);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
}

.Center {
  height: 1px;
  width: 100%;
  background-color: #009dff;
}

.Up,
.Down {
  position: relative;

  background-color: white;
  color: #1d2023;

  width: 100%;
  height: 50%;

  text-align: center;

  overflow: hidden;
}

.Num {
  display: inline-block;
  font-size: 1em;
  line-height: 1;
  vertical-align: text-top;
}

.Up {
  border-radius: var(--countdown-digit-border-radius)
    var(--countdown-digit-border-radius) 0 0;
  transform-origin: bottom;
}

.Up .Num {
  transform: translateY(0.125em);
}

.Down {
  border-radius: 0 0 var(--countdown-digit-border-radius)
    var(--countdown-digit-border-radius);
  transform-origin: top;
}

.Down .Num {
  transform: translateY(
    calc(-0.5em - 1px)
  ); /* half of (digit height - num height) + half of digit height + center line height */
}

@keyframes flipUp {
  from {
    transform: rotateX(0deg);
  }

  to {
    transform: rotateX(-90deg);
  }
}

.Prev .Up {
  z-index: 3;
  animation: flipUp 0.5s linear both;
}

.Active .Up {
  z-index: 2;
}

@keyframes flipDown {
  from {
    transform: rotateX(90deg);
  }

  to {
    transform: rotateX(0deg);
  }
}

.Active .Down {
  z-index: 3;
  animation: flipDown 0.5s 0.5s linear both;
}

.Prev .Down {
  z-index: 2;
}
