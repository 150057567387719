.Badge {
  position: relative;
  display: flex;
  align-items: center;
  min-width: fit-content;
}

.PhotoBacking {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  height: 100%;
  overflow: hidden;
  border: var(--badge-border);
  border-radius: 50%;
}

.Photo {
  height: 100%;
}

.Initials {
  text-transform: uppercase;
  overflow: hidden;
  color: white;
  text-align: center;
  font-size: 20px;
  font-family: Avenir-Heavy;
}

.Name {
  margin-left: 8px;
  display: inline-flex;
  justify-content: space-around;
  flex-direction: column;
  margin-bottom: 0px;
}
