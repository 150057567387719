.List {
  display: flex;
}

.Shimmer {
  border-radius: 4px;
  height: 64px;
  margin-right: 8px;

  &:last-of-type {
    margin-right: 0;
  }
}

.ShortShimmer {
  height: 41px;
}
