/**
 * Use font-display: block for a smoother note font loading ux.
 *
 * See: https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display
 * See: https://addyosmani.com/blog/google-fonts-font-display/
 * See: https://font-display.glitch.me/
 *
 * Todo: move this import and font picker font options into theming system.
 */
@import url('https://fonts.googleapis.com/css?family=Allura|EB+Garamond:600|Lato:300|Lora:400i|Montserrat:500|Oswald:500|Tangerine|Rubik:500|Alfa+Slab+One|Baloo|Bitter:700|Comfortaa:700|Corben:700|Mansalva|Notable|Nunito:800|Shadows+Into+Light|Work+Sans:500|Righteous&display=block');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Luckiest+Guy&family=Paytone+One&family=Josefin+Slab&family=Press+Start+2P&family=Homemade+Apple&family=Fredericka+the+Great&family=Knewave&family=Yeseva+One&family=Gochi+Hand&family=Berkshire+Swash&family=Amatic+SC&family=Orbitron&family=Codystar&family=Shrikhand&family=Astloch&family=Sedgwick&family=Poppins&family=Slackey&family=Berkshire+Swash&family=Abril+Fatface&family=Lemon&family=Gravitas+One&family=Coiny&family=Mogra&family=Margarine&display=block');

.Note {
  white-space: pre-wrap;
  word-break: break-word;
  word-wrap: break-word;
  hyphens: manual;
  padding: 40px 32px;
  width: 100%;
}

.Frame {
  border: 10px solid white;
  border-radius: 4px;
  box-shadow: 0px 4px 12px #00000029;
}

@media (min-width: 1024px) {
  .Note {
    padding: 56px 48px;
  }
}
