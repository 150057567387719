.Root {
  position: relative;
  overflow-x: clip;
  overflow-y: scroll;
  height: 100%;
}

.MainContent {
  display: flex;
  justify-content: center;
  overflow: unset;
}

.RevealContent {
  width: 320px;
  margin: 20px 0;
  text-align: center;
}

.MuteChip {
  margin-top: 24px;
  position: absolute;
  z-index: 2;
}

.MuteButton {
  position: fixed;
  top: 56px;
  right: 24px;
  z-index: 2;
}

.Wizard {
  margin-top: 50px;
}

.PackagingControls {
  margin: 10px 0 32px;
  padding: 26px 0;
  position: absolute;
  width: 100%;
}

.DragOnDragDocumentBody {
  user-select: none;
}

.Footer {
  padding-top: 8px;
  padding-bottom: 62px; /*54 for Drawer + 8 Spacing */
}

.AutoAdvance {
  padding: 0px 16px;
  position: sticky;
  top: 20px;
  width: 100%;
  z-index: 1;
}
