.root {
  position: relative;
}

.img {
  left: 0;
  position: absolute;
  top: 0;
}

.gif {
  left: 0;
  position: relative;
  top: 0;
}

.gif img {
  width: 100%;
}
