.Root {
  align-items: center;
  background: white;
  border-radius: 21px;
  height: 42px;
  display: flex;
  justify-content: space-between;
  max-width: 400px;
  margin: 0 auto;
  transition: 0.4s;
}

.PlayPauseButtonMini {
  visibility: hidden;
}

.MuteButtonVisibility {
  visibility: hidden !important;
}

.ProgressBarMini {
  max-width: 300px;
}

.RootSmall {
  background: transparent;
  max-width: none;
}

.MuteButton {
  background: transparent;
  border-color: transparent;

  &:disabled {
    background: transparent;
    border-color: transparent;
  }

  &:hover {
    background-color: #dedede;
    border-color: #d8d8d8;
  }

  &:focus {
    box-shadow: 0 0 0 3px #eee;
  }
}

.ProgressBar {
  background: white;
  width: 100%;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 4px;
}

.ProgressBar:first-of-type {
  margin-left: 16px;
}
.ProgressBar:last-of-type {
  margin-right: 16px;
}

.PlaybackButton {
  background: white;
  border-radius: 50%;
  color: black;
  font-size: 18px;
  height: 42px;
  width: 42px;
  max-width: 42px;
  min-width: 42px;
  outline: none;

  &:hover {
    background-color: #dedede;
    border-color: #d8d8d8;
  }

  &:focus {
    box-shadow: 0 0 0 3px #eee;
  }
}
