* {
  box-sizing: border-box;
}

.ModalOverlay {
  z-index: 1080;
  backdrop-filter: blur(10px);
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ChallengeIframe {
  visibility: visible;
  border: none;
  margin: auto;
  overflow: hidden;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

.CloseButton {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}

.FlipCard {
  position: fixed;
  top: 50%;
  left: 50%;
  max-width: 300px;
  transform: translate3d(-50%, -50%, 0);
  width: 100%;
  z-index: 1001;
  opacity: 1;
}

.RotateDevice {
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99999;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #252830;
}

.RotateDevice img {
  margin: 0 auto;
}

.RotateDeviceText {
  margin: 20px;
  width: 50%;
  text-align: center;
  color: white;
  font-weight: 100;
  font-size: 18px;
}

.Overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0) scale(10);
  z-index: 1;
  opacity: 1;
  background-color: rgba(40, 60, 70, 0.8);
}
