.Root {
  background: transparent;
  border: none;
  padding: 0;
  width: 100%;
  margin-bottom: 8px;
}

.Icon {
  height: 40px;
  object-fit: cover;
  width: 40px;
}

.Label {
  composes: Label from '../../../../../../common/src/Chip/Chip.module.scss';
  font-size: 18px;
  font-weight: bold;
  margin-left: 16px;
  padding: 0;
  text-align: left !important;
}

.Active {
  box-shadow: 0 0 0 3px var(--primary);
}

.Translucent {
  opacity: 0.5;
}
