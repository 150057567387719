.Root {
  text-align: center;
  height: 100%;
  max-width: 344px;
  width: 100%;
  transition: height 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  position: relative;
}

.PaymentCard {
  box-shadow: none;
  padding: 24px 25px;
  position: relative;
  z-index: 3;
  border-radius: 24px;
  button {
    line-height: 1.5;
  }
}

.Perforation {
  align-items: center;
  transition: all 0.3s;
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 1px;
  width: calc(100% - 36px);
  z-index: 3;
  .Line {
    width: 5px;
    height: 1px;
    background-color: #aeb5bc;
  }
}

.BottomContents {
  padding: 24px 24px;
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: space-between;
}

.OfferContents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 3;
  width: 100%;
  padding: 0px;

  .BonusPlusSign {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .BonusText {
    margin: 0;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .OfferImage {
    max-height: 52px;
    max-width: 100%;
    margin-bottom: 15px;
    max-width: 100%;
    object-fit: contain;
  }

  .OfferTitle {
    margin: 0px;
    margin-bottom: 16px;
  }
  .OfferSubTitle {
    margin: 0;
    margin-bottom: 12px;
    opacity: 0.9;
  }
  .OfferDisclaimer {
    margin: 0;
    margin-bottom: 12px;
    opacity: 0.7;
  }
}

.OfferButton {
  z-index: 2;
}

.RemoveOfferButton {
  margin: 0;
  z-index: 3;
  &:hover {
    cursor: pointer;
  }
}

.TopContainer,
.BottomContainer {
  width: 100%;
  padding: 0px;
  border-radius: 14px 14px 24px 24px;
  position: relative;
  box-shadow: 0px 8px 32px 0px #00000026;
  background: white;
}

.TopContainer::after,
.BottomContainer::after {
  content: '';
  width: 100%;
  background: inherit;
  z-index: 2;
  position: absolute;
  left: 0px;
  border-radius: 24px;
}

.TopContainer,
.TopContainer::after {
  border-radius: 24px;
}

.BottomContainer,
.BottomContainer::after {
  border-radius: 24px;
}

.TopContainer::after {
  bottom: -1px;
}

.BottomContainer::after {
  top: -1px;
}

.MerchantEditable:hover {
  cursor: pointer;
}
