.Root {
  padding: 16px;
}

.Buttons {
  display: flex;
}

.EmailButton,
.FacebookMessengerButton,
.SmsButton,
.WhatsappButton {
  flex: 1;
  height: 64px;
  margin-right: 8px;

  & button {
    height: 100%;
    min-width: 50px;
    width: 100%;
  }
}

.CopyButton,
.NavigatorShareButton {
  margin-top: 22px;
}

.EmailButton {
  margin-right: 0;
}
