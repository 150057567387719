.Root {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.Printable {
  //   width: calc(min(100vw, 8.5 / 12 * 100vh));
  //   height: calc(min(12 / 8.5 * 100vw, 100vh));
  margin-left: auto;
  margin-right: auto;

  width: 1100px;
  min-width: 1100px;
  aspect-ratio: 8.5 / 12;

  @media print {
    width: 100vw !important;
    min-width: 100vw !important;
    height: 100vh !important;
  }
}

.Overlay {
  position: fixed;
  padding: 16px 32px;
  margin: 16px;
  z-index: 1;
  bottom: 0;
  width: auto;
  background: white;
  border-radius: 16px;

  @media print {
    display: none;
  }
}
