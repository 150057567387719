.Circle3 {
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  height: 640px;
  justify-content: center;
  position: relative;
  width: 640px;
}

.Circle2 {
  align-items: center;
  background: rgba(255, 255, 255, 0.15);
  display: flex;
  height: 545px;
  justify-content: center;
  position: relative;
  width: 545px;
}

.Circle1 {
  align-items: center;
  background: rgba(255, 255, 255, 0.85);
  display: flex;
  height: 470px;
  justify-content: center;
  position: relative;
  width: 470px;
}

.Content {
  position: absolute;
  top: 85px;
  width: 320px;
}
