.Root {
  align-items: center;
  display: flex;
}

.RowReverse {
  flex-direction: row-reverse;
}

.Icon {
  border-radius: 50%;
  height: 25px;
  width: 25px;
}

.Label {
  font-size: 14px;
  font-weight: 400;
}

.MarginLeft {
  margin-left: 16px;
}

.MarginRight {
  margin-right: 16px;
}
