:root {
  --D1-font: 100 50px/54px #{$font-family-sans-serif};
  --D1-text-transform: none;

  --H1-font: 100 32px/32px #{$font-family-sans-serif};
  --H1-text-transform: none;

  --H2-font: normal 18px/22px #{$font-family-sans-serif};
  --H2-text-transform: none;

  --B1-font: normal 16px/24px #{$font-family-sans-serif};
  --B1-letter-transform: none;

  --Overline-font: normal 12px/20px #{$font-family-sans-serif};
  --Overline-text-transform: none;
  --Overline-opacity: 1;

  --button-font: normal 14px/14px #{$font-family-sans-serif};
  --button-letter-spacing: 0px;
  --button-text-transform: #{$btn-text-transform};

  --footer-font-family: 'AvenirNextLTPro-Demi';
}
