.Title {
  margin-bottom: 32px;
  text-align: center;
}

.Button {
  margin: 0 auto;
  min-width: 140px;
  position: relative;
  z-index: 1;
}
