$whatsapp-primary: #31ce61;

.Button {
  background-color: $whatsapp-primary;
  border-color: $whatsapp-primary;
  font-size: 27px;

  &.active,
  &:active,
  &:not(:disabled):not(.disabled):active {
    background-color: darken($whatsapp-primary, 10%);
    border-color: darken($whatsapp-primary, 12.5%);
  }

  &.disabled,
  &:disabled {
    background-color: $whatsapp-primary;
    border-color: $whatsapp-primary;
  }

  &.focus,
  &:focus,
  &:hover {
    background-color: darken($whatsapp-primary, 7.5%);
    border-color: darken($whatsapp-primary, 7.5%);
  }
}
