@font-face {
  font-family: 'AvenirNextLTPro';
  font-style: normal;
  font-weight: normal;
  src: local('AvenirNextLTPro-Regular'),
    url('./fonts/AvenirNextLTPro-Regular.otf');
}

@font-face {
  font-family: 'AvenirNextLTPro';
  font-style: normal;
  font-weight: 100;
  src: local('AvenirNextLTProLight'), url('./fonts/AvenirNextLTProLight.otf');
}

@font-face {
  font-family: 'AvenirNextLTPro';
  font-style: normal;
  font-weight: 600;
  src: local('AvenirNextLTProMedium'), url('./fonts/AvenirNextLTProMedium.otf');
}

@font-face {
  font-family: 'AvenirNextLTPro';
  font-style: normal;
  font-weight: 700;
  src: local('AvenirNextLTPro-Demi'), url('./fonts/AvenirNextLTPro-Demi.otf');
}

.logo {
  background: var(--header-logo-image) no-repeat;
  background-position: center;
  background-size: contain;
}

[class*='Preview_PreviewingBar'] p {
  font: var(--B1-font) !important;
}

[class*=' Button-module_Neutral'] {
  background-color: #d8d8d8;
  border-color: #d8d8d8;
  box-shadow: 0 2px 0 0 #9b9b9b !important;
  color: #000;
}

[class*='Button-module_Invert'] {
  background-color: var(--primary-accent-color) !important;
  border-color: var(--primary-accent-color) !important;
  box-shadow: 0 2px 0 0 var(--secondary-accent-color) !important;
  color: white;
}

[class*='ArrowButton_Root']:hover i {
  transition: color 0.3s;
  color: white;
}

:root {
  --header-logo-image: url('./header-logo.svg');
  --logo-image: url('./header-logo.svg');
  --header-background-color: var(--primary-accent-color);
}
