// See: https://github.com/twbs/bootstrap/blob/main/scss/_root.scss
// See: https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss

// Note: Any bootstrap/scss variable can be exposed through css variables

$btn-border-color: transparent !default;

:root {
  --primary-accent-color: #{$primary};
  --secondary-accent-color: #{$secondary};
  --empty-background-color: #fff;

  --error-alert-color: #{$danger};

  --button-border-radius: #{$btn-border-radius};
  --button-border-color: #{$btn-border-color};
  --button-border-width: #{$btn-border-width};
  --button-disabled-color: #{$btn-link-disabled-color};
  --button-font: #{$btn-font-weight} 18px/18px #{$font-family-sans-serif};
  --button-font-weight: #{$btn-font-weight};
  --button-letter-spacing: #{$btn-letter-spacing};
  --button-loading-state-background: #{$btn-loading-state-background};
  --button-text-transform: #{$btn-text-transform};

  --logo-image: url('https://static.vouchrsdk.com/assets/images/vouchr_logo.svg');

  --D1-font: 500 50px/50px #{$font-family-sans-serif};
  --D1-text-transform: none;

  --H1-font: 500 32px/32px #{$font-family-sans-serif};
  --H1-text-transform: none;

  --H2-font: 500 18px/22px #{$font-family-sans-serif};
  --H2-text-transform: none;

  --B1-font: normal 16px/24px #{$font-family-sans-serif};
  --B1-letter-spacing: initial;

  --Overline-font: normal 12px/20px #{$font-family-sans-serif};
  --Overline-letter-spacing: 2.28px;
  --Overline-text-transform: none;
  --Overline-opacity: 1;
}
