.root {
  align-items: center;
  background-color: rgba(20, 20, 20, 0.64);
  border: none;
  color: white;
  display: flex;
  font-size: 24px;
  height: 100%;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  transition: 0.4s;
}
