.Content {
  padding: 24px;
}

.Image {
  height: 300px;
  margin-bottom: 20px;
  object-fit: cover;
  width: 100%;
}

.PrimaryButton {
  margin-bottom: 12px;
}

@media (min-width: 576px) {
  .Dialog {
    max-width: 415px;
  }
}
