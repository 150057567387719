.GiftItem {
  text-align: center;
}

.AudioGiftItem {
  width: 100%;
}

.AudioSubtitleContainer {
  background-color: rgba(255, 255, 255, 0.64);
  border-radius: var(--button-border-radius);
  height: 32px;
  margin-top: 8px;
  transition: opacity 0.3s !important;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.AudioSubtitle {
  font-size: 15px;
  color: #767676;
}

.AudioSubtitleVisible {
  opacity: 1;
}

.AudioSubtitleHidden {
  opacity: 0;
}

.CursorPointer {
  cursor: pointer;
}

.GiftItemBorder {
  box-sizing: border-box;
  border: var(--gift-item-border);
  border-radius: var(--gift-item-border-radius);
  box-shadow: var(--gift-item-box-shadow);
}

.GiftItemAnimation:nth-child(odd) {
  animation: var(--gift-item-animation-odd);
}

.GiftItemAnimation:nth-child(even) {
  animation: var(--gift-item-animation-even);
}

.ImageCard {
  position: relative;
  margin: auto;
}

.Controls {
  padding: 0 12px;
  position: absolute;
  z-index: 2;
}

.MediaTypeImage {
  max-width: 100%;
}

.MediaTypeVideo {
  max-height: 90vh;
  width: 560px;
  max-width: 100%;
  border: var(--gift-item-border-radius);
  border-radius: var(--gift-item-border-radius);
  overflow: hidden;
}

.MediaTypeYouTube {
  width: 100%;
  position: relative;
}

.From {
  text-align: right;
  font-size: 14px;
}

.PlayButton {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0 10%;
  opacity: 1;
  z-index: 1;
  cursor: pointer;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.CarouselItem {
  padding: 0;
  margin: 0;
  width: 100vw;
}

.CarouselViewportHeight {
  max-height: 90vh;
}

.GiftItem .MediaTypeYouTubeCarousel,
.MediaTypeVideo {
  max-width: 100%;
  padding-top: 25px;
}

@media (max-width: 499px) {
  .MediaTypeYouTubeCarousel iframe,
  .MediaTypeVideo {
    width: 90vw;
  }
}

a {
  word-break: break-word;
  word-wrap: break-word;
}

.Modal {
  z-index: 100;
}

.gif {
  width: 100%;
}
