.Buttons {
  display: flex;
  gap: 8px;
}

.Vertical {
  flex-direction: column;
}

.EmailButtonMobile,
.FacebookMessengerButton,
.SmsButton,
.WhatsappButton {
  flex: 1;
  height: 64px;
  transform: scale(0.8);

  & button {
    height: 100%;
    width: 100%;
  }
}

.EmailButtonMobile {
  margin-right: 0;
}

.BottomButton {
  width: 100%;
  margin-top: 0 !important;
}

.EmailButtonDesktop {
  width: 100%;
}

.CopyButton {
  width: 100%;
}

.Print {
  aspect-ratio: 8.5 / 12;
  width: 1000px; // used to match the resolution of the print page
  overflow: hidden;
  height: 0px;

  @media print {
    width: 100vw !important;
    height: 100vh !important;
  }
}
