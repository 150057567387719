.Root {
  min-height: 280px;
}

.Container {
  padding: 0 24px;
}

.CancelButton {
  color: #1d2023;
  line-height: 1;
  margin-bottom: 20px;
  padding: 0;
}

.Error {
  background: #eee;
  border-radius: 8px;
  padding: 20px;
}

.Description {
  color: #4a4d4f;
  font-weight: 400;
  margin-bottom: 24px;
  text-align: left;
}

.GratitudeListContainer {
  padding: 24px;
}

.ShareSectionContainer {
  border-top: 1px solid #f1f1f1;
  min-height: 200px;
  padding-bottom: 32px;
  padding-top: 32px;
}
