.ChallengeOver {
  padding-bottom: 32px;
  width: 116px;
}

.Instructions {
  padding: 0 0 16px 0;
}

.Score {
  color: var(--primary-accent-color);
}

.TotalScore {
  align-items: center;
  display: flex;
  margin-bottom: 16px;
}

.TotalScore > h2,
.TotalScore > h3 {
  align-items: center;
  padding: 0px 8px;
}

.Controls {
  max-width: 240px;
  width: 100%;
}

.ImageWithControls {
  margin-bottom: 16px;
  width: 100%;
}
