.Lead {
  font-size: 32px;
}

.Rest {
  font-size: 20px;
}

.Amount {
  font-size: 32px;
}
