.Card {
  background-color: white;
  position: relative;
  border-radius: 14px;
  box-shadow: 0px 8px 32px #00000026;
  padding: 32px 40px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.CardWithBadge {
  padding-top: 50px;
}

.CardContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Badge {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 64px;
  height: 64px;
}

.CardContent h1,
.CardContent h4,
.CardContent h5,
.CardContent p {
  padding-bottom: 8px;
}

.CardContent h2,
.CardContent h3 {
  padding-bottom: 16px;
}
