.Root {
  position: relative;
  display: inline-block;
  padding-top: 0.1em;
  padding-bottom: 0.15em;
}

.Line {
  opacity: 0;
  position: absolute;
  left: 0;
  height: 3px;
  width: 100%;
  transform-origin: 0 0;
}

.LineTop {
  top: 0;
}

.LineBottom {
  bottom: 0;
}
