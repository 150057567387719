.Root {
  padding-bottom: 20vh;
}

div.Packaging {
  margin: 32px auto 0px auto;
  position: relative;
  text-align: initial;
  z-index: -10;
}

.Lock {
  background-color: white;
  opacity: 1;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  border: 3px solid black;
  border-radius: 50%;
}

.Lock i {
  font-size: 2.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  color: black;
}

.PackagingControls {
  margin: 10px 0 20px;
  padding-left: 24px;
  padding-right: 24px;
  position: absolute;
  width: 100%;
}
