.Grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  width: 100%;
  height: 100%;

  @media not print {
    grid-gap: 1px;
    background-color: lightgray;
  }
}

.Front,
.Back,
.InsideLeft,
.InsideRight {
  background-color: white;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 10%;
  background-size: cover;
  background-position: center;
  text-align: center;
}

.Front,
.Back {
  transform: scale(-1);
}

.Back {
  justify-content: end;
}

.Front {
  padding: 10%;
}

.Image {
  height: 100%;
  max-width: 100%;
}

.QRCodeContainer {
  display: flex;
  flex-direction: row;
  gap: 0.1em;
  justify-content: center;
  align-content: center;
  align-items: center;
  white-space: pre-line;
  padding: 8px;
  border-radius: 4px;
  text-align: left;
}

.QRCode {
  border-radius: 4px;
}

.ScanText {
  line-height: 1.1;
  margin-bottom: 0px;
}

.Logo {
  height: 8%;
  width: 100%;
  margin-bottom: 8px;
  background-image: var(--logo-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
