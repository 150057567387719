.Shimmer {
  width: 100%;
  height: 100%;
  display: inline-block;

  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  display: inline-block;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
}

@media (min-width: 640px) {
  .Shimmer {
    animation-duration: 2s;
  }
}

@media (min-width: 1280px) {
  .Shimmer {
    animation-duration: 3s;
  }
}

@keyframes shimmer {
  0% {
    background-position: -100vw;
  }

  100% {
    background-position: 100vw;
  }
}
