.Root {
  z-index: 2000;
}

.Dialog {
  max-width: 414px;
  position: fixed;
  z-index: 1050;
}

.ShareListItemIcon {
  border-radius: var(--button-border-radius);
  height: 100px;
  width: 100px;
}
