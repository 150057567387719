.Title {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.Title h1 {
  animation: fade 300ms ease-out;
}

.Title iframe {
  min-width: 100%;
  width: 100px;
  *width: 100%;
  padding: 0;
  border: none;
  display: block;
  pointer-events: none;
}

.accessibilityHeader {
  border: 0;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

:global(.fade-enter) {
  opacity: 0.01;
  transform: scale(0.9) translateY(50%);
}

:global(.fade-enter-active) {
  opacity: 1;
  transform: scale(1) translateY(0%);
  transition: all 300ms ease-out;
}

:global(.fade-exit) {
  opacity: 1;
  transform: scale(1) translateY(0%);
}

:global(.fade-exit-active) {
  opacity: 0.01;
  transform: scale(0.9) translateY(50%);
  transition: all 300ms ease-out;
}

@keyframes fade {
  0% {
    opacity: 0.01;
    transform: scale(0.9) translateY(50%);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(0%);
    transition: all 300ms ease-out;
  }
}
