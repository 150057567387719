.Root {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
}

.Placeholder {
  border-radius: 4px;
  flex: 1 1 140px;
  height: 54px;
  margin: 4px;
}

.ShareListItemAsSingle {
  height: 125px;
  background-color: #f1f1f1;
  &:active {
    background-color: #f1f1f1 !important;
  }
  &:hover {
    background-color: #f1f1f1;
    cursor: default !important;
  }
}

@media (min-width: 360px) {
  .Placeholder {
    flex: 1 1 150px;
  }
}
