.Root {
  height: initial;
  width: initial;
}

.Scrollable {
  overflow: visible !important;
  padding-right: 0px !important;
}

.Dialog {
  max-width: 414px;
  position: fixed;
  z-index: 1050;
  bottom: 0px;
}
