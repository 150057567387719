.Button {
  align-items: center;
  display: flex;
  justify-content: center;
  letter-spacing: var(--button-letter-spacing);
  text-transform: var(--button-text-transform);
  border-radius: var(--button-border-radius, 12px);
  @if variable-exists(button-border-width) {
    border-width: var(--button-border-width, undefined);
    border-color: var(--button-border-color, undefined);
  }
}

body .Circular {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
}

body .Rounded {
  border-radius: 999px;
}

.Spinner div {
  background: var(--button-loading-state-background);
}

:global(.btn-link) {
  font-weight: var(--button-font-weight);
}

$translucent: rgba(255, 255, 255, 0.85);

:global(.btn-translucent) {
  background-color: $translucent;
  border-color: $translucent;
  color: #212529;

  &.active,
  &:active {
    background-color: darken($translucent, 10%);
    border-color: darken($translucent, 12.5%);
  }

  &.disabled,
  &:disabled {
    background-color: $translucent;
    border-color: $translucent;
  }

  &.focus,
  &:focus,
  &:hover {
    background-color: darken($translucent, 7.5%);
    border-color: darken($translucent, 7.5%);
  }
}

:global(.btn-outline-translucent) {
  background-color: transparent;
  border-color: $translucent;
  color: $translucent;

  &.active,
  &:active {
    border-color: darken($translucent, 12.5%);
    color: $translucent;
  }

  &.disabled,
  &:disabled {
    border-color: $translucent;
    color: $translucent;
  }

  &.focus,
  &:focus,
  &:hover {
    border-color: darken($translucent, 7.5%);
    color: $translucent;
  }
}

$dark-translucent: rgba(0, 0, 0, 0.85);

:global(.btn-dark-translucent) {
  background-color: $dark-translucent;
  border-color: $dark-translucent;
  color: white;

  &.active,
  &:active {
    background-color: lighten($dark-translucent, 10%);
    border-color: lighten($dark-translucent, 12.5%);
  }

  &.disabled,
  &:disabled {
    background-color: $dark-translucent;
    border-color: $dark-translucent;
  }

  &.focus,
  &:focus,
  &:hover {
    background-color: lighten($dark-translucent, 7.5%);
    border-color: lighten($dark-translucent, 7.5%);
    color: white;
  }
}

:global(.btn-outline-dark-translucent) {
  background-color: transparent;
  border-color: $dark-translucent;
  color: $dark-translucent;

  &.active,
  &:active {
    border-color: lighten($dark-translucent, 12.5%);
    color: $dark-translucent;
  }

  &.disabled,
  &:disabled {
    border-color: $dark-translucent;
    color: $dark-translucent;
  }

  &.focus,
  &:focus,
  &:hover {
    border-color: lighten($dark-translucent, 7.5%);
    color: $dark-translucent;
  }
}
