.Root {
  align-items: center;
  background: #d1d1d126;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  line-height: 1;
  margin-bottom: 12px;
  padding: 12px;
}

.CurrencyValue {
  margin-top: 2px;
}
