.base:focus {
  outline: 0;
}

.overlay-base {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 60, 70, 0.8);
  opacity: 0;
  transition: all 0.2s ease-out;
  z-index: 1060;
}

.overlay-base_after-open {
  opacity: 1;
}

.overlay-base_before-close {
  opacity: 0;
}

.ReactModal__Body--open {
  overflow: hidden;
  height: 100vh;
}
