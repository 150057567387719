.ErrorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 15px;
  flex-direction: column;
}

.ErrorContent h1,
.ErrorContent h2,
.ErrorContent h3 {
  text-transform: none;
  text-align: left;
}

.ErrorImage {
  width: 332px;
  height: 215px;
  margin-right: 0px;
  margin-bottom: 8px;
  background-image: url('https://static.vouchrsdk.com/assets/images/error/monster.svg');
  background-size: contain;
  background-repeat: no-repeat;
}

.ErrorContent {
  margin-left: 0px;
  width: 332px;
}

.ErrorContent h1 {
  margin-bottom: 12px;
}

.ErrorBreak {
  width: 332px;
  height: 1px;
  background-color: #cccccc;
  margin: 22px 0px;
}

.ErrorButton {
  width: 100%;
  margin-bottom: 8px;
}

@media (min-width: 768px) {
  .ErrorContent h1,
  .ErrorContent h2,
  .ErrorContent h3 {
    text-align: left;
  }
  .ErrorContainer {
    flex-direction: row;
  }
  .ErrorImage {
    margin-right: 40px;
    width: 430px;
    height: 315px;
  }
  .ErrorContent {
    margin-left: 40px;
  }
}

@media (max-height: 115px) {
  .ErrorContent {
    border: 1px solid #c6c6c6;
    border-radius: 3px;
    width: 333px;
    height: 100px;
    margin: 0 auto;
    padding: 0px 14px;
  }

  .ErrorContent h1 {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 500;
  }

  .ErrorContent h2,
  .ErrorContent h3 {
    font-size: 16px;
    font-weight: 400;
  }

  .ErrorImage {
    display: none;
  }
  .ErrorBreak {
    display: none;
  }
}
