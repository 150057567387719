.Root {
  border: none;
  border-radius: 20px;
  padding: 0;
  width: 160px;
  display: flex;
  background-color: rgba($color: #000000, $alpha: 0.85);
  align-items: center;
}

.Root:hover {
  background-color: rgba($color: #000000, $alpha: 0.85);
}

.Root:active,
.Root:focus {
  background-color: rgba($color: #000000, $alpha: 0.85) !important;
}

.Icon {
  background: #000;
  border-radius: 50%;
  font-size: 24px;
  padding: 6px;
}

.Label {
  margin: 0;
  text-align: center;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
}
