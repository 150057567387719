.Root {
  position: relative;
}

.Root img {
  width: 100%;
}

.PlaybackButtonContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.Gif {
  width: 100%;
}

.ExternalLink {
  bottom: 0;
  position: absolute;
  right: 0;
  z-index: 2;
}
