.AudioPlayerContainer {
  height: 40px;
  padding: 0px 24px 0px 16px;
  background-color: #333333;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--button-border-radius);
  margin-bottom: 16px;
  margin: 0 auto;
}

.SeekDisabled {
  pointer-events: none;
}

.MusicalNote {
  color: white;
  font-size: 20px;
  margin-right: 16px;
}

.Time {
  font-size: 16px;
  display: flex;
  color: white;
  margin-left: 16px;
  font-weight: 100;
}

.Duration {
  width: 45px;
  text-align: center;
}

.Delimiter {
  margin: 0 10px;
}

@media (min-width: 640px) {
  .AudioPlayerContainer {
    width: 100%;
  }
}
