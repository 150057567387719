.D1 {
  font: var(--D1-font);
  font-weight: var(--D1-font-weight);
  letter-spacing: var(--D1-letter-spacing);
  text-transform: var(--D1-text-transform);
}

.H1 {
  /* SHOULD BE CHANGED TO H2 IN THE FUTURE */
  font: var(--H1-font);
  font-weight: var(--H1-font-weight);
  letter-spacing: var(--H1-letter-spacing);
  text-transform: var(--H1-text-transform);
}

.H2 {
  /* SHOULD BE CHANGED TO H5 IN THE FUTURE */
  font: var(--H2-font);
  font-weight: var(--H2-font-weight);
  letter-spacing: var(--H2-letter-spacing);
  text-transform: var(--H2-text-transform);
}

.H3 {
  font: var(--H3-font);
  font-weight: var(--H3-font-weight);
  letter-spacing: var(--H3-letter-spacing);
  text-transform: var(--H3-text-transform);
}

.H4 {
  font: var(--H4-font);
  font-weight: var(--H4-font-weight);
  letter-spacing: var(--H4-letter-spacing);
  text-transform: var(--H4-text-transform);
}

.H6 {
  font: var(--H6-font);
  font-weight: var(--H6-font-weight);
  letter-spacing: var(--H6-letter-spacing);
  text-transform: var(--H6-text-transform);
}

.Overline {
  font: var(--Overline-font);
  font-weight: var(--Overline-font-weight);
  letter-spacing: var(--Overline-letter-spacing);
  text-transform: var(--Overline-text-transform);
  opacity: var(--Overline-opacity);
}

.Caption {
  font: var(--Caption-font);
  font-weight: var(--Caption-font-weight);
  letter-spacing: var(--Caption-letter-spacing);
  text-transform: var(--Caption-text-transform);
  opacity: var(--Caption-opacity);
}

.buttonText {
  font: var(--button-font);
  font-weight: var(--button-font-weight);
  text-transform: var(--button-text-transform);
  letter-spacing: var(--button-letter-spacing);
}

.B1 {
  font: var(--B1-font);
  font-weight: var(--B1-font-weight);
  text-transform: var(--B1-text-transform);
  letter-spacing: var(--B1-letter-spacing);
}

.D1,
.H1,
.H2,
.H3,
.H4,
.H6,
.B1,
.Overline,
.Caption,
.buttonText {
  color: var(--text-color, --secondary-accent-color, #1d2023);
}
