.Dialog {
  background: white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0px 8px 32px #00000026;
  left: 0;
  margin: 0 auto;
  max-width: 500px;
  position: absolute;
  right: 0;
  width: auto;
}

.Modal {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.Tab {
  height: 54px;
}

.SpringOffset {
  padding-bottom: 50px;
}
