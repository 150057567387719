.Root {
  border-radius: 4px;
  padding: 16px;
}

.Frame {
  border: 10px solid white;
  box-shadow: 0px 4px 12px #00000029;
}

.Note {
  padding: 0;
  white-space: pre-wrap;
  word-break: break-word;
  word-wrap: break-word;
  hyphens: manual;
}

.Tags {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
  margin-top: 10px;
}

.Tag {
  border: 1px solid #e3e3e3;
  margin: 4px;
  min-width: 75px;
  padding: 4px 8px;

  background-color: #f1f1f1;
  color: #212529;
  font-size: 12px;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  word-break: break-all;
}

.Tag:hover {
  background-color: #dedede !important;
  border: 1px solid #d8d8d8;
  color: #212529;
  box-shadow: none !important;
}

.Tag:active,
.Tag:focus {
  color: #212529 !important;
  background-color: #d8d8d8 !important;
  border-color: #d1d1d1 !important;
}

.Tag:last-of-type {
  margin-right: 0;
}
