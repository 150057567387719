.Root:hover {
  background: none !important;
  border: none !important;
}

.Root:focus {
  background: none !important;
}

.Icon {
  font-size: 25px;
  margin-top: -1px;
}

.Pulse {
  animation: pulse 2s infinite;
  transition: all 0.2s ease-in-out;

  &:hover {
    animation: none;
    transform: scale(1.1);
  }
}

@keyframes pulse {
  70% {
    box-shadow: 0 0 0 8px rgba(51, 51, 51, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(51, 51, 51, 0);
  }
}
