.ShakeEffect {
  animation-name: shake-effect;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes shake-effect {
  0% {
    transform: rotate(0deg);
  }

  3% {
    transform: rotate(3deg);
  }

  6% {
    transform: rotate(0deg);
  }

  9% {
    transform: rotate(-3deg);
  }

  12% {
    transform: rotate(0deg);
  }

  15% {
    transform: rotate(3deg);
  }

  18% {
    transform: rotate(0deg);
  }

  21% {
    transform: rotate(-3deg);
  }

  24% {
    transform: rotate(0deg);
  }

  27% {
    transform: rotate(3deg);
  }

  30% {
    transform: rotate(0deg);
  }

  33% {
    transform: rotate(-3deg);
  }

  36% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
