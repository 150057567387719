.Root {
  box-shadow: 0px 3px 6px #00000029;
  border: none;
}

.Root {
  &:focus {
    border: none;
    box-shadow: none;
  }

  &:hover {
    background-color: var(--secondary-accent-color);
  }
}
