.Root {
  background: white;
  border-radius: 6px;
  overflow: hidden;
}

.Note {
  border-radius: 0;
  padding: 16px;
}

.OverlayCard {
  position: absolute !important;
}
