.root {
  pointer-events: none;
  position: relative;
  user-select: none;
}

.background {
  position: absolute;
  z-index: 0;
}

/* In combination with the shape's background z-index of 0, the foreground's
 * z-index of 100 allows content to have a range of z-indexes ensuring the
 * content exits the shape between the shape's background and its foreground
 */
.foreground {
  position: absolute;
  z-index: 100;
}

.scaleWidth {
  width: 100%;
  height: auto;
}
