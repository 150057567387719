$btn-font-size: 18;
$btn-font-weight: bold;
$btn-letter-spacing: 0.41px;
$btn-loading-state-background: #ffffff;
$btn-text-transform: uppercase;

$font-family-sans-serif: 'Inter', sans-serif;
$font-family-sans-serif-two: 'Inter', sans-serif;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1240px,
  xxl: 1400px,
);

$link-decoration: none;
$link-hover-decoration: none;

$h1-font-size: 50px;
$h2-font-size: 32px;
$h3-font-size: 18px;
$h4-font-size: 16px;
$h5-font-size: 12px;

$danger: #d33939;
$light: #f1f1f1;
$primary: #0066ff;
$secondary: #153e43;
