.ToLabel {
  text-align: center;
  word-break: break-word;
  margin-bottom: 8px;
}

.Title {
  margin-bottom: 8px;
  text-align: center;
  word-break: break-word;
}

.Description {
  margin-bottom: 8px;
  text-align: center;
  word-break: break-word;
}

.Button {
  margin: 16px auto 0px auto;
  min-width: 140px;
  position: relative;
  word-break: break-word;
  z-index: 1;
}
